import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { EnvironmentProvider } from '../common/context/EnvironmentContext/EnvironmentContext';
import LocalLayout from '../common/components/LocalLayout';
import '../tailwind.css';

const LocalApp = ({ Component, pageProps }) => {
  useEffect(() => {
    async function config() {
      console.log('next.js local app [init config]: ', configuration);

      const header = async () => {
        return {
          'x-twisp-tenant-id': '9a450fee-166e-502b-86f3-3ee0c2bb812c',
        };
      };

      const endpoint = (region) => {
        return {
          name: region,
          endpoint: 'http://localhost:8080',
          custom_header: header,
        };
      };

      Amplify.configure({
        API: {
          endpoints: [endpoint('local')],
        },
      });
    }
    config();
  }, []);

  return (
    <LocalLayout>
      <EnvironmentProvider local={true}>
        <Component {...pageProps} />
      </EnvironmentProvider>
    </LocalLayout>
  );
};

export { LocalApp };
