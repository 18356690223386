import React, { useEffect, useState, createContext } from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { defaultState, localState, regions } from './helpers';

const EnvironmentContext = createContext(defaultState);

interface EnvironmentProviderProps {
  children: React.ReactNode;
  local: boolean;
  session: CognitoUserSession;
}

const getInitialStateFromSession = (session, local, prev) => {
  if (local) {
    return prev;
  } else {
    const payload = session.getIdToken().decodePayload();

    const accountIds = payload['account_ids'].split(' ');
    const accountNicks = payload['account_nicks'].split(' ');
    const accountRegions = payload['account_regions'].split(' ');
    const showLegacyUX = payload['legacy_ux'] === 'true';

    const accounts = accountIds.map((id: string, i: number) => {
      return {
        key: id,
        region: accountRegions[i] || regions.find(Boolean),
        nick: accountNicks[i],
      };
    });

    const accountKey = localStorage.getItem('twisp:account');
    const accountFound = accounts.find((a: { key: string }) => a.key === accountKey);
    const account = accountFound || accounts.find(Boolean);

    const regionKey = localStorage.getItem('twisp:region');
    const regionFound = regions.find((a) => a.key === regionKey);
    const regionDefault = regions.find((a) => a.key === account.region);
    const region = regionFound || regionDefault;

    const initialState = {
      ...prev,
      account,
      accounts,
      region,
      showLegacyUX,
    };
    return initialState;
  }
};

const EnvironmentProvider = ({ children, local, session }: EnvironmentProviderProps) => {
  const prev = local ? localState : defaultState;

  const initialState = getInitialStateFromSession(session, local, prev);

  const [env, setEnv] = useState(initialState);

  const setAccount = (key: string) => {
    setEnv((prev) => {
      return {
        ...prev,
        account: prev.accounts.find((a) => a.key === key),
      };
    });
  };

  const setRegion = (key: string) => {
    setEnv((prev) => {
      return {
        ...prev,
        region: prev.regions.find((r) => r.key === key),
      };
    });
  };

  useEffect(() => {
    if (!env.account) return;
    localStorage.setItem('twisp:account', env.account?.key);
    localStorage.setItem('twisp:region', env.region?.key ?? 'REGION NOT FOUND');
  }, [env]);

  return (
    <EnvironmentContext.Provider value={{ ...env, setAccount, setRegion }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export default EnvironmentContext;
export { EnvironmentProvider };
