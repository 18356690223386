import { EnvironmentState } from './types';

export const regions = [
  { nick: 'N. Virginia', key: 'us-east-1' },
  { nick: 'Ohio', key: 'us-east-2' },
  { nick: 'N. California', key: 'us-west-1' },
  { nick: 'Oregon', key: 'us-west-2' },
  { nick: 'Sydney', key: 'ap-southeast-2' },
];

export const defaultState: EnvironmentState = {
  local: false,
  accounts: [],
  regions: regions,
  showLegacyUX: true,
};

const localAccount = {
  nick: 'Local',
  region: 'local',
  key: '000000000000',
};

const localRegion = {
  nick: 'Local',
  key: 'local',
};

export const localState: EnvironmentState = {
  local: true,
  accounts: [localAccount],
  account: localAccount,
  regions: [localRegion],
  region: localRegion,
  showLegacyUX: true,
};
