import { LocalApp } from './_app.local.jsx';
import { AwsApp } from './_app.aws.jsx';

let isLocal = false;

if (typeof window !== 'undefined') {
  isLocal = window.origin.indexOf("http://localhost:") == 0
}

let App
if (process.env.NODE_ENV === "development") {
  App = LocalApp
} else if (isLocal) {
  App = LocalApp
} else {
  App = AwsApp
}

export default App;
