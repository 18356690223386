import React, { useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import Layout from '../common/components/Layout';
import '../tailwind.css';

const AwsApp = ({ Component, pageProps }) => {
  useEffect(() => {
    async function config() {
      console.log('next.js aws app [init config]: ', configuration);

      const header = async () => {
        return {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        };
      };

      const endpoint = (region) => {
        return {
          name: region,
          endpoint: configuration.api.replace(configuration.region, region),
          custom_header: header,
        };
      };

      Amplify.configure({
        Auth: {
          region: configuration.region,
          userPoolId: configuration.userPoolId,
          userPoolWebClientId: configuration.userPoolClientId,
          mandatorySignIn: true,
        },
        API: {
          endpoints: [
            endpoint('us-east-1'),
            endpoint('us-east-2'),
            endpoint('us-west-1'),
            endpoint('us-west-2'),
            endpoint('ap-southeast-2'),
          ],
        },
      });
    }
    config();
  }, []);

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
};

export { AwsApp };
