import React, { useCallback, useEffect, ReactNode } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import logo from '@content/images/twisp-logo-black.svg';
import '@aws-amplify/ui-react/styles.css';
import { EnvironmentProvider } from '@context/EnvironmentContext/EnvironmentContext';

const formFields = {
  setupTOTP: {
    QR: {
      totpIssuer: 'Twisp',
    },
  },
};

const components = {
  Header() {
    return (
      <div className="flex flex-col justify-center p-4">
        <img alt="Twisp logo" className="h-16" src={logo.src} />
      </div>
    );
  },
};

interface AuthenticateProps {
  children?: ReactNode;
};

const Authenticate: React.FC<AuthenticateProps> = ({ children }) => {
  const { toSignIn } = useAuthenticator();

  const refreshState = useCallback(() => {
    Auth.currentSession()
      .then((session) => {
        if (!session.isValid()) {
          toSignIn();
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const handler = ({ payload }) => {
      switch (payload.event) {
        case 'configured':
        case 'signIn':
        case 'signIn_failure':
        case 'signOut':
        case 'tokenRefresh':
        case 'tokenRefresh_failure':
          refreshState();
      }
    };

    Hub.listen('auth', handler);

    return () => {
      Hub.remove('auth', handler);
    };
  }, [refreshState]);

  return (
    <Authenticator
      components={components}
      formFields={formFields}
      loginMechanisms={['email']}
      hideSignUp={true}
    >
      {({ user }) => {
        const session = user.getSignInUserSession();
        return (
          <EnvironmentProvider session={session} local={false}>
            {children}
          </EnvironmentProvider>
        );
      }}
    </Authenticator>
  );
};

export default Authenticate;
